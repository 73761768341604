<template>
  <router-view />
</template>

<script>
import jwt from '@/http/requests/auth';

export default {
  name: 'GrantIndex',
  async created() {
    jwt.init();
  },
};
</script>
